<template>
  <div class="video__container">
    <!-- <video-embed
      @play="test"
      css="embed-responsive-16by9"
      :src="slice.primary.video.embed_url"
      class="player"
      id="play"
    ></video-embed> -->
    <vimeo-player v-if="loading" :controls="contr" ref="player" :video-id="id" class="embed-responsive" @play="play" @pause="pause" :options="options"></vimeo-player>
  </div>
</template>

<script>
export default {
  name: "WorkVideo",
  data() {
    return {
      id: "",
      loading: false,
      height: 200,
      contr: true,
      options:{
        
        // color: "db3832",
        color: "23ff7f",
        transparent: true,
        byline: false,
        
      }
    }
  },
  props: {
    slice: Object,
  },
  methods: {
    setIframeHeight() {
      // var iframe_cont = document.getElementById("play");
      // var iframe_cont = document.querySelector(".player");
      // console.log(iframe_cont)
      var height_video =
        (this.slice.primary.video.height / this.slice.primary.video.width) *
        100;
      // iframe_cont.style.paddingBottom = height_video + "%";
      document.documentElement.style.setProperty("--ratio", height_video + "%");
    },
    play(){
      
      this.$store.commit('play')
    },
    pause(){
  
      this.$store.commit('pause')
    }
  },
  mounted() {
    // console.log(this.slice);
    // const regex = /[0-9]{9}/g;
    const regex = /vimeo\.com\/(\d+)/;
    const video = this.slice.primary.video.embed_url.match(regex)
    this.id = video[1]
    this.loading = true
    this.setIframeHeight();
  },
};
</script>
<style lang="scss">
:root {
  --ratio: 56.25%;
}
// #player {
//   margin: auto;
//   width: 50%;
// }
// .player{
//   padding: 0 30%;
//   margin: auto;
//   border: 1px solid red;
// }
// iframe{
//   border: 1px solid red;
// }

// .html5-video-player{
//   border: 1px solid red;
// }

// .embed-responsive-item{
//   border-radius: 20px;
//   width: 100%;
//   height: 400px;
//   border: 1px solid red;
// }

.video__container {
  

  width: clamp(150px, 90%, 1080px);

  margin: auto;
  margin-top: 82px;
  // padding-bottom: max(var(--ratio), 607.5px);
  // padding-bottom: max(56.25%, 607.5px);
  // background: green;
  // padding-bottom: 56.25% ;
}
.embed-responsive {
  position: relative;
  margin: auto;
  padding-bottom: var(--ratio);
  // padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
iframe {
  // opacity: 0.2;
  border: none;
  // border: 1px solid red;
  border-radius: 12px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
