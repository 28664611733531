<template>
  <div class="home">
    <!-- <div id="myVideo" class="test"></div> -->
    <video
      autoplay
      muted
      loop
      id="myVideo"
      playsinline="true"
      disablePictureInPicture="true"
    >
      <source src="../assets/video/cani2.mp4" type="video/mp4" />
    </video>
    <div class="header" :class="{ hidden: hidden }" v-if="loading">
      VALENTINA <br />
      FURIAN
    </div>
    <div class="content">
      <h1 id="hero_title">VALENTINA<br />FURIAN</h1>
      <div v-if="loading" class="about">
        <router-link to="/about" class="title">ABOUT</router-link>
        <p v-html="about.text"></p>
        <!-- <p v-html="response.data.text[0].text"></p> -->
        <!-- <pre id="app" v-html="JSON.stringify(response, null, 2)"></pre> -->
      </div>
    </div>
    <div class="content__two">
      <router-link to="/calendar" class="title" v-if="events">NEWS</router-link>
      <div
        class="event__container events__grid"
        v-for="(i, index) in events.length < 10 ? events.length : 10"
        :key="`event-${index}`"
      >
        <a
          v-if="events[index].link.url"
          :href="events[index].link.url"
          target="blank"
          ><h3 class="event__title">{{ events[index].name[0].text }}</h3></a
        >
        <h3 v-else class="event__title">{{ events[index].name[0].text }}</h3>
        <div class="event__infoContainer">
          {{ new Date(events[index].start).getDate() }}.{{
            addZero(events[index].start)
          }}<span v-if="events[index].end"
            >-{{ new Date(events[index].end).getDate() }}.{{
              addZero(events[index].end)
            }}.{{ new Date(events[index].end).getFullYear() }}</span
          ><span v-else
            >.{{ new Date(events[index].start).getFullYear() }}</span
          >
          <prismic-rich-text
            :field="events[index].info"
            :htmlSerializer="htmlSerializer"
          />
          <!-- <div class="event__info" v-html="$prismic.asHtml(event.info)" :htmlSerializer="htmlSerializer" /> -->
        </div>
      </div>
      <!-- <div class="selected__container" v-if="loading">
        <h3 class="title">WORKS</h3>
        <router-link
          :to="`/works/${works[i].slugs}`"
          v-for="(n, i) in works"
          :key="i"
          >{{ works[i].data.title[0].text
          }}<span v-if="i < works.length - 1">, </span>
        </router-link>
      </div> -->
      <div v-if="loading" class="contacts__container">
        <h3 class="title">CONTACTS</h3>
        <p class="contacts">
          {{ contacts.contact[0].text.replace("\n", " / ") }}
        </p>
        <p class="contacts">{{ contacts.email }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // Initialize "response"
      about: null,
      events: [],
      loading: false,
      works: null,
      contacts: null,
      interval: null,

      scroll: 1,
      observer: null,
      enableAnimation: true,
      id: "hero_title",
      hidden: true,
    };
  },
  methods: {
    initIntersection() {
      const _self = this;
      const target = document.getElementById(this.id);

      let options = {
            // threshold: 0,
            rootMargin: "0px 0px 200px 0px"
          }

      this.observer = new IntersectionObserver(_self.handleIntersection, options);
      target
        ? this.observer.observe(target)
        : this.observer.disconnect();
    },
    handleIntersection(entries) {
      entries.map((entry) => {
        if (entry.isIntersecting) {
          this.hidden = true;
          // console.log("gas");
          // } else console.log("boh");
        } else {
          this.hidden = false;
          // console.log("boh");
        }
      });
    },

    getElHover(e) {
      if (
        e.target.nodeName === "P" ||
        e.target.nodeName === "H2" ||
        e.target.nodeName === "H3" ||
        e.target.nodeName === "SPAN" ||
        e.target.nodeName === "A"
      ) {
        // console.log(e.target.nodeName);
        this.scroll = 0;
      } else this.scroll = 1;
    },
    pageScroll() {
      // var k = document.querySelector(".home")
      // var body = document.body
      if (window.innerWidth >= 768) {
        // var k = document.querySelector(".footer").getBoundingClientRect();
        var a = document.querySelector("#app").getBoundingClientRect();
        // var b = document.body.getBoundingClientRect();
        window.scrollBy(0, this.scroll);
        // console.log(k.offsetHeight)
        // console.log(window.innerHeight + window.scrollY)
        // console.log(window.scrollY)
        // console.log(document.body.scrollHeight)
        // console.log(k.y - window.innerHeight + k.height)
        // if (k.y + window.innerHeight - k.height <= 0) window.scroll(0, 0);
        if (a.bottom <= -300) window.scroll(0, 0);
        // if (k.height - window.innerHeight - k.y > 0 && k.height - window.innerHeight - k.y < 10) window.scroll(0, 0);
        // console.log("Diovaaaa")
      }
    },
    async getContent() {
      // Query the API and assign the response to "response"
      const response = await this.$prismic.client.getSingle("home");
      const events = await this.$prismic.client.query(
        // this.$prismic.Predicates.at("document.type", "event"),
        this.$prismic.Predicates.at('my.event.active', true),
        { orderings: "[my.event.start desc]" }
      );
      const wrks = await this.$prismic.client.query(
        this.$prismic.Predicates.at("document.type", "work"),
        { orderings: "[my.work.date_creation desc]" }
      );
      const abt = await this.$prismic.client.getSingle("about");
      //   console.log(response.results)
      events.results.forEach((el) => {
        // console.log(el)
        this.events.push(el.data);
      });
      this.works = wrks.results;
      this.contacts = abt.data;
      this.about = response.data.about[0];
      this.loading = true;
    },
    htmlSerializer(type, element, content, children) {
      // If element is a list item,
      if (type === "paragraph") {
        // return some customized HTML.
        return `<p class="exhibitions" style="margin:0">${children.join(
          ""
        )}</p>`;
      }
      /// Otherwise, return null.
      return null;
    },
    addZero(mydate) {
      var date = new Date(mydate);
      var string = ("0" + (date.getMonth() + 1)).slice(-2);
      return string;
    },
    removCose() {
      clearInterval(this.pageScroll);
      document.removeEventListener("mouseover", this.getElHover);
    },
  },
  created() {
    // Call the API query method
    this.getContent();
  },
  mounted() {
    // document.addEventListener("mouseover", (e) => this.getElHover(e));
    document.addEventListener("mouseover", this.getElHover);
    this.interval = setInterval(this.pageScroll, 20);

    if (this.enableAnimation) {
      if (document.getElementById(this.id)) {
        this.initIntersection();
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
    document.removeEventListener("mouseover", this.getElHover);
  },
};
</script>

<style lang="scss" scoped>
#myVideo {
  position: fixed;
  top: 0;
  // left: 0;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;
  // width: 100vw;
  // height: 100vh;
  min-width: 100%;
  min-height: 100%;
  background: black;

  z-index: -1;
  @media screen and (max-width: 768px){
    // left: 50%;
    // transform: translate(-50%, 0);
  }
}

.test {
  background: chocolate;
}

.content {
  z-index: 200;
  display: grid;
  // grid-template-columns: auto;
  grid-template-rows: repeat(3, 1fr);
  width: 100%;
  height: 100vh;
  margin-top: 80vh;
  @media screen and (max-width: 768px) {
    margin-top: 0vh;
    grid-template-rows: 80vh auto;
    height: auto;
  }

  // margin: auto;
  // margin-top: 38vh;

  h1 {
    -webkit-mix-blend-mode: exclusion;
    mix-blend-mode: exclusion;
    -moz-mix-blend-mode: exclusion;
    -o-mix-blend-mode: exclusion;
    -ms-mix-blend-mode: exclusion;
    // z-index: 100;
    align-self: center;
    margin: 0 auto;
    grid-row: 2 / 3;
    color: white;
    text-align: center;
    font-size: 6vw;
    letter-spacing: 1px;
    font-family: Neue haas, Helvetica, Arial, sans-serif;
    font-weight: bold;
    // line-height: 110px;
    @media screen and (max-width: 768px) {
      padding-top: 10vh;
      font-size: 30px;
      grid-row: 1 / 2;
    }
  }
}

.about {
  // mix-blend-mode: hard-light;
  z-index: 99;
  align-self: end;
  padding: 0 20%;
  margin-top: 200px;
  margin-bottom: 50px;
  text-align: center;
  grid-row: 3 / 4;
  color: white;
  font-family: Nukle, Helvetica, Arial, sans-serif;
  font-weight: normal;
  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    /* identical to box height, or 144% */

    text-align: center;
    letter-spacing: 0.01em;
    font-family: Nukle, Helvetica, Arial, sans-serif;
  }
  p {
    margin-top: 28px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 162%;

    text-align: center;
    letter-spacing: 0.02em;
    letter-spacing: 1px;
    font-family: Nukle, Helvetica, Arial, sans-serif;
    @media screen and (max-width: 768px) {
      margin-top: 7vh;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0 10%;
    grid-row: 2/end;
    margin-top: 8vh;
  }
}

.home {
  // min-height: 300vh;
}

#app {
  color: white;
}

.content__two {
  z-index: 200;
  width: 80%;
  margin: auto;
  margin-top: 200px;
  text-align: center;
  color: white;
  font-family: Nukle, Helvetica, Arial, sans-serif;
  font-weight: normal;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
}

.events__title {
  grid-column-start: 2;
  font-family: Nukle, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 144%;

  text-align: left;
  letter-spacing: 0.01em;
  margin-bottom: 64px;
  color: #ffffff;
}

.event__container {
  margin-bottom: 32px;
}

.event__title {
  text-align: right;
  grid-column-start: 1;
  margin: 0;
  font-family: Nukle, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 160%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}
.event__infoContainer {
  text-align: left;
  grid-column-start: 2;
  font-family: Nukle, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 144%;

  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #ffffff;
  padding-right: 20%;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    padding-right: 0;
  }
}

.events__grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  &:first-of-type {
    margin-top: 42px;
  }
  a {
    color: white;
    text-decoration: none;
    & :hover {
      text-decoration: underline;
    }
  }
}

.selected__container {
  margin: auto;
  margin-top: 200px;
  width: 50%;

  font-family: Nukle, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  /* or 160% */
  color: white;
  h3 {
  }
  a {
    color: white;
    text-decoration: none;
  }

  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;

  a:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 768px) {
    width: 80%;
  }
}

.title {
  font-size: 18px;
  line-height: 144%;
  font-weight: normal;

  text-align: center;
  letter-spacing: 0.01em;
  color: white;
  text-decoration: none;

}

  a.title:hover{
    text-decoration: underline;
  }

.contacts {
  font-family: Nukle, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 162%;
  margin-top: 10px;

  text-align: center;
  letter-spacing: 0.01em;
}

.contacts__container {
  margin-top: 200px;
  padding-bottom: 200px;
}

.header {
  position: fixed;
  width: 100vw;
  top: 0;
  padding-top: 20px;
  color: white;
  font-size: 30px;
  text-align: center;
  letter-spacing: 1px;
  font-family: Neue haas, Helvetica, Arial, sans-serif;
  font-weight: bold;
  transition: all 5s;
}

.hidden {
  display: none;
}
</style>
