<template>
  <div class="menu">
    <div class="menuVoice voiceHome">
      <a href="/"
        >VALENTINA <br />
        FURIAN</a
      >
    </div>
    <div class="menulist">
      <a href="/works"><div class="menuVoice voice">WORKS</div></a>
      <a href="/calendar"><div class="menuVoice voice">CALENDAR</div></a>
      <a href="/about"><div class="menuVoice voice">ABOUT</div></a>
    </div>
   <div class="contacts__container" v-if="loading">
        <p class="contacts">
        <p class="contacts">{{ about.email }}</p>
      </div>
  </div>
</template>

<script>
export default {
    data() {
    return {
      // Initialize "response"
      about: null,
      loading: false
    };
  },
  methods: {
    async getContent() {
      // Query the API and assign the response to "response"
      const response = await this.$prismic.client.getSingle("about");
      this.about = response.data;
      this.loading = true
      // console.log(this.about);
    },
  },
  created() {
    // Call the API query method
    this.getContent();
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: black;
  z-index: 300;
  padding-top: 20px;
  @media screen and (max-width:768px) {
    // padding-top: 20vh;
  }
}
.menuVoice {
  width: 100%;
  font-size: 12vh;
  color: white;
  text-align: center;
  font-family: Neue haas, Helvetica, Arial, sans-serif;
  font-weight: normal;
  a {
    color: white;
    text-decoration: none;
    &:hover {
    }
  }
  @media screen and (max-width:768px) {
    font-size: 6vh;
  }
}
.menulist {
  padding-top: 4vh;
  padding-bottom: 4vh;
}
.voice {
  text-decoration: none;
  padding-top: 1vh;
  &:hover {
    background: var(--main-color);
    color: black;
  }
}

.voiceHome {
  font-weight: bold;
  font-size: 30px;
  padding-bottom: 15vh;
  letter-spacing: 2px;
}

.contacts {
  font-family: Nukle, Helvetica, Arial, sans-serif; 
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 162%;
  margin-top: 10px;
  color: white;
  text-align: center;
  letter-spacing: 0.01em;
  @media screen and (max-width:768px) {
    margin-top: 0px;
  }
}

.contacts__container {
  margin-top: 3vh;
  color: white;
  text-align: center;
  display: flex;
  gap: 20px;
  padding: 0 20px;
  justify-content: center;
  flex-wrap: wrap;
  @media screen and (max-width:768px) {
    gap: 0px;
    margin-top: 6vh;
  }
}

.mob{
  @media screen and (max-width:768px) {
    display: none;
  }
}
</style>
